import React from "react"
import styled from "styled-components"
import Image from "../components/image"

const FbOpinion = styled.div`
    display: flex;
    ${({ theme }) => theme.media.miniphone} {
        flex-wrap: wrap;
        justify-content: center;
    }
    ${({ theme }) => theme.media.desktop} {
        flex-wrap: nowrap;
    }
`
const TextFOpinion = styled.div`
    flex-basis: 80%;
    position: relative;
    padding: 0 3rem;
    margin: 3rem auto 5rem;
    ${({ theme }) => theme.media.miniphone} {
        flex-basis: 99%;
        padding: 0 1.5rem;
    }
    ${({ theme }) => theme.media.desktop} {
        flex-basis: 80%;
        margin: 0rem auto 5rem;
        padding: 0 2.5rem;
    }
`

const Foto = styled.div`
    flex-basis: 25%;
    padding: 0;

    ${({ theme }) => theme.media.miniphone} {
        display: none;
    }
    ${({ theme }) => theme.media.desktop} {
        display: block;
    }
`

const Foto2 = styled(Foto)`
    flex-basis: 85%;
    padding: 0 1rem 2rem;

    ${({ theme }) => theme.media.miniphone} {
        display: block;
    }
    ${({ theme }) => theme.media.desktop} {
        display: none;
    }
`

const Triangle = styled.div`
    position: absolute;
    top: 0;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${props => {
        let height = props.myheight
        let width = props.mywidth
        if (props.myside === "left") {
            return height + "px " + width + "px 0 0"
        } else {
            return "0 " + width + "px " + height + "px 0"
        }
    }};
    border-color: ${props => {
        if (props.myside === "left") {
            return props.mycolor + " transparent transparent transparent"
        } else {
            return "transparent " + props.mycolor + " transparent transparent"
        }
    }};
`

const TriangleL = styled(Triangle)`
    left: 0;
`
const TriangleR = styled(Triangle)`
    right: 0;
`

const H3 = styled.h3`
    text-align: ${props =>
        props.isLeft ? "left" : props.isRight ? "right" : "center"};
    margin-bottom: ${props => (props.isMarginSmall ? ".1rem" : "1rem")};
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.important};
    span {
        text-transform: uppercase;
    }
    ${({ theme }) => theme.media.miniphone} {
        width: 70%;
        margin: 0 auto 0.5rem;
    }
    ${({ theme }) => theme.media.desktop} {
        width: 90%;
        margin: 0 auto 2rem;
    }
`

const P = styled.div`
    font-size: 1rem;
    text-align: center;
    margin: 1.25rem 1rem;
`

const FacebookOpinion = () => (
    <FbOpinion>
        <Foto2>
            <Image imgName="statkiFb" />
        </Foto2>
        <Foto>
            <Image imgName="statkiFb" isFloatLeft />
        </Foto>
        <TextFOpinion>
            <TriangleL
                myside="left"
                mycolor="#23d0c7"
                mywidth="40"
                myheight="80"
            />
            <TriangleR
                myside="right"
                mycolor="#23d0c7"
                mywidth="40"
                myheight="80"
            />
            <H3>
                <span>O FEST PODRÓŻACH</span>, czyli opinie z facebooka:
            </H3>
            <article>
                <P>
                    Bardzo profesjonalne podejście, miła i&nbsp;sympatyczna
                    obsługa, a&nbsp;przede wszystkim nietuzinkowy sposób
                    zwiedzania świata. Polecam i&nbsp;na pewno skorzystam
                    ponownie! (Justyna)
                </P>
                <P>
                    <Image imgName="dotFacebook" />
                </P>
            </article>
            <article>
                <P>
                    Magda niczego nie robi w&nbsp;połowie, jeśli jest to FEST!
                    Jeśli zwiedzać to w&nbsp;nietuzinkowy sposób. Gorąco
                    polecam, wystarczy raz, by połknąć bakcyla. (Ewa)
                </P>
                <P>
                    <Image imgName="dotFacebook" />
                </P>
            </article>
            <article>
                <P>
                    Dziękujemy...Za wspaniałą opiekę i&nbsp;przekazanie wiedzy.
                    Było wspaniale i&nbsp;chcemy więcej (Jolanta)
                </P>
                <P>
                    <Image imgName="dotFacebook" />
                </P>
            </article>
            <article>
                <P>
                    100 procent profesjonalizmu świetnie przygotowanie. Magda
                    robi bardzo dobrą atmosferę, dobrze się z&nbsp;nią
                    współpracuje. Polecam każdemu przewodnik z&nbsp;pasją
                    z&nbsp;krwi i&nbsp;kości oby każdy miał takie podejście.
                    Miło spędzony czas na Bałkanach Albania (Marcin)
                </P>
                <P>
                    <Image imgName="dotFacebook" />
                </P>
            </article>
            <article>
                <P>
                    Przepiękne widoki, miła atmosfera a&nbsp;przede wszystkim
                    bardzo miła i&nbsp;kompetentna pani przewodnik. Pani Magdo
                    brawo i&nbsp;dziękuję. (Anita)
                </P>
            </article>
        </TextFOpinion>
        <Foto>
            <Image imgName="statkiFb2" isFloatLeft />
        </Foto>
    </FbOpinion>
)

export default FacebookOpinion
