import React from "react"
import styled from "styled-components"
import Image from "../components/image"
import H1 from "./h1"
import FacebookOpinion from "./fbOpinion"

const Main = styled.div`
    margin: 8rem auto 2rem;
    ${({ theme }) => theme.media.miniphone} {
        width: 98%;
        margin: 3rem auto 2rem;
    }
    ${({ theme }) => theme.media.tablet} {
        flex-wrap: nowrap;
        margin: 4rem auto 2rem;
    }
    ${({ theme }) => theme.media.desktop} {
        width: 75.5%;
        margin: 6rem auto 2rem;
    }
`

const Triangle = styled.div`
    position: absolute;
    top: 0;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${props => {
        let height = props.myheight
        let width = props.mywidth
        if (props.myside === "left") {
            return height + "px " + width + "px 0 0"
        } else {
            return "0 " + width + "px " + height + "px 0"
        }
    }};
    border-color: ${props => {
        if (props.myside === "left") {
            return props.mycolor + " transparent transparent transparent"
        } else {
            return "transparent " + props.mycolor + " transparent transparent"
        }
    }};
`

const TriangleL = styled(Triangle)`
    left: 0;
`
const TriangleR = styled(Triangle)`
    right: 0;
`

const P1 = styled.p`
    font-size: 1rem;
    text-align: ${props =>
        props.isLeft ? "justify" : props.isRight ? "justify" : "center"};
`

const ContentHead = styled.div`
    display: flex;
    margin: 3rem 0;
`

const ContentInfo = styled.div`
    position: relative;
    padding: ${props =>
        props.side === "left" ? "0 1.3rem 0 3rem" : " 0 3rem 0 1.3rem"};
    ${({ theme }) => theme.media.miniphone} {
        flex-basis: 100%;
        margin: 1rem 0;
    }
    ${({ theme }) => theme.media.desktop} {
        flex-basis: 38%;
        margin: 3rem 0;
    }
`

const H3 = styled.h3`
    text-align: ${props =>
        props.isLeft ? "left" : props.isRight ? "right" : "center"};
    margin-bottom: ${props => (props.isMarginSmall ? ".1rem" : "1rem")};
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.important};
    span {
        text-transform: uppercase;
    }
    ${({ theme }) => theme.media.miniphone} {
        width: 70%;
        margin: 0 auto 0.5rem;
    }
    ${({ theme }) => theme.media.desktop} {
        width: 90%;
        margin: 0 auto 0.5rem;
    }
`

const Foto = styled.div`
    flex-basis: 25%;
    padding: 0;

    ${({ theme }) => theme.media.miniphone} {
        display: none;
    }
    ${({ theme }) => theme.media.desktop} {
        display: block;
    }
`

const WrapperInfo = styled.div`
    display: flex;
    justify-content: space-between;
    ${({ theme }) => theme.media.miniphone} {
        flex-direction: column;
    }
    ${({ theme }) => theme.media.desktop} {
        flex-direction: row;
        flex-basis: 38%;
    }
`
const FotoCenter = styled(Foto)`
    flex-basis: 20%;
    margin: auto;

    ${({ theme }) => theme.media.miniphone} {
        display: block;
    }
    ${({ theme }) => theme.media.desktop} {
        display: block;
    }
`
const FotoSerce = styled(Foto)`
    flex-basis: 30%;
`
const PHead = styled.div`
    padding: 0 1rem;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
`
const WrapperDot = styled.div`
    margin: 3rem auto 5rem;
    justify-content: center;
    display: flex;
    ${({ theme }) => theme.media.miniphone} {
        margin: 1rem auto 0;
    }
    ${({ theme }) => theme.media.phone} {
        margin: 1rem auto 2rem;
    }
    ${({ theme }) => theme.media.desktop} {
        margin: 3rem auto 5rem;
    }
`

const MainPage = () => (
    <Main>
        <H1 title="FEST PODRÓŻE" page="mainPage" isLeft />
        <ContentHead>
            <FotoSerce>
                <Image imgName="serce" isFloatLeft />
            </FotoSerce>
            <div>
                <H3>
                    <span>
                        To dla nas wielka radość dzielić się z&nbsp;wami fest
                        podróżami!
                    </span>
                </H3>
                <PHead>
                    <p>
                        Zapraszamy na wspólne wyjazdy! Odkryjcie z&nbsp;nami
                        fascynujące miejsca i&nbsp;historie, poznajcie odmienne
                        zwyczaje, lokalną kuchnię i&nbsp;cieszcie się magią
                        chwili w&nbsp;malowniczych zakątkach Europy.
                    </p>
                    <p>
                        Kto ma więcej energii niech jedzie z&nbsp;nami na
                        wyjazdy aktywne – idealne dla tych, którym trzeba sporo
                        ruchu i&nbsp;więcej wrażeń. Dla spragnionych przeżyć
                        duchowych – pielgrzymki, kto szuka relaksu
                        w&nbsp;ciekawych miejscach – też znajdzie coś dla
                        siebie.
                    </p>
                    <p>
                        A&nbsp;jak nie znajdzie, niech zbierze grupę
                        i&nbsp;napisze do nas – stworzymy nowy program „szyty na
                        miarę”! Chcemy spełniać Wasze marzenia!
                    </p>
                </PHead>
            </div>
        </ContentHead>
        <WrapperInfo>
            <ContentInfo side="left">
                <TriangleL
                    myside="left"
                    mycolor="red"
                    mywidth="15"
                    myheight="30"
                />
                <H3 isRight isMarginSmall>
                    <span>delektuj się podróżą</span>
                </H3>
                <P1 isRight>
                    Każdy program został starannie przemyślany, tak aby zawsze
                    znalazł się czas na małe przyjemności! Podróż to przecież
                    także niespiesznie wypita kawa, posiłek w&nbsp;lokalnej
                    knajpce, czy relaks w&nbsp;otoczeniu natury. Nie lubimy
                    przeładowanych programów, lubimy delektować się podróżą. A
                    Wy?
                </P1>
            </ContentInfo>
            <FotoCenter>
                <Image imgName="kawa" isFloatLeft />
            </FotoCenter>
            <ContentInfo>
                <TriangleR
                    myside="right"
                    mycolor="red"
                    mywidth="15"
                    myheight="30"
                />
                <H3 isLeft isMarginSmall>
                    <span>wyjazdy aktywne</span>
                </H3>

                <P1 isRight>
                    Wolisz spędzać czas w&nbsp;ruchu zamiast wylegiwać się przez
                    tydzień na plaży? Mamy dla Ciebie wyjazdy, gdzie łączymy
                    rekreacyjne uprawianie sportu ze zwiedzaniem. w&nbsp;wersji
                    zimowej proponujemy biegówki i&nbsp;narty zjazdowe, od
                    wiosny nordic walking, rowery, kajaki i&nbsp;windsurfing,
                    więc chętnie i&nbsp;dla Was zorganizujemy aktywny wyjazd
                    z&nbsp;poznawaniem okolicy!
                </P1>
            </ContentInfo>
        </WrapperInfo>
        <WrapperInfo>
            <ContentInfo side="left">
                <TriangleL
                    myside="left"
                    mycolor="red"
                    mywidth="15"
                    WrapperDot
                    myheight="30"
                />
                <H3 isRight isMarginSmall>
                    <span>festiwale, festyny, fest podróże</span>
                </H3>
                <P1 isRight>
                    Jeśli jesteś ciekawski i&nbsp;lubisz jak coś się dzieje to
                    idealnie trafiłeś. Festiwale gastronomiczne i&nbsp;muzyczne,
                    święto wina i&nbsp;piwa, świąteczne jarmarki, imprezy
                    wpisane na listę kulturowego dziedzictwa - to wszystko
                    z&nbsp;nami! Poznaj tradycje i&nbsp;zwyczaje regionów, daj
                    się uwieść smakom i&nbsp;zapachom! Odwiedź najpiękniejsze
                    regiony!
                </P1>
            </ContentInfo>
            <FotoCenter>
                <Image imgName="kajak" isFloatLeft />
            </FotoCenter>
            <ContentInfo>
                <TriangleR
                    myside="right"
                    mycolor="red"
                    mywidth="15"
                    P3dots
                    myheight="30"
                />
                <H3 isLeft isMarginSmall>
                    <span>pielgrzymki</span>
                </H3>
                <P1 isRight>
                    Od dawna współpracujemy z&nbsp;grupami parafialnymi, stąd
                    też w&nbsp;naszej ofercie znajdują się atrakcyjne programy
                    pielgrzymek. Wspólnie wyruszamy w&nbsp;drogę śladami
                    świętych lub nawiedzając mniej i&nbsp;bardziej znane
                    sanktuaria. Z przyjemnością ułożymy niestandardowe programy
                    na życzenie.
                </P1>
            </ContentInfo>
        </WrapperInfo>
        <WrapperInfo>
            <ContentInfo side="left">
                <TriangleL
                    myside="left"
                    mycolor="red"
                    mywidth="15"
                    myheight="30"
                />
                <H3 isRight isMarginSmall>
                    <span>relaks i&nbsp;wypoczynek</span>
                </H3>
                <P1 isRight>
                    Dla tych, którzy na co dzień żyją w&nbsp;szybkim tempie
                    a&nbsp;na urlopie marzą o błogim lenistwie mamy kilka
                    ciekawych propozycji. A&nbsp;gdy już ktoś zapragnie
                    rozejrzeć się po okolicy to każdym programie znajdzie
                    możliwość wyruszenia na atrakcyjną wycieczkę.
                </P1>
            </ContentInfo>
            <FotoCenter>
                <Image imgName="rzezba" isFloatLeft />
            </FotoCenter>
            <ContentInfo>
                <TriangleR
                    myside="right"
                    mycolor="red"
                    mywidth="15"
                    myheight="30"
                />
                <H3 isLeft isMarginSmall>
                    <span>wyjazdy na zamówienie</span>
                </H3>
                <P1 isRight>
                    To nasza specjalność. Chcesz wyruszyć z&nbsp;grupą znajomych
                    na wyjazd a&nbsp;nie wiesz jak się do tego zabrać? Co roku
                    organizujecie wyjazd z&nbsp;firmy na wycieczkę
                    i&nbsp;organizator zawiódł? A&nbsp;może działasz w
                    stowarzyszeniu/ klubie i&nbsp;chcecie wyruszyć na wspólną
                    podróż? Skontaktuj się z&nbsp;nami, a&nbsp;my przygotujemy
                    wyjazd od A&nbsp;do Z.
                </P1>
            </ContentInfo>
        </WrapperInfo>
        <WrapperDot>
            <Image imgName="dotGrey" isGrey />
            <Image imgName="dotGrey" isGrey />
            <Image imgName="dotGrey" isGrey />
        </WrapperDot>
        <FacebookOpinion />
    </Main>
)

export default MainPage
