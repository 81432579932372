import React from "react"
// import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Posts from "../components/posts"
import SEO from "../components/seo"
import MainPage from "../components/mainPage"



const IndexPage = ({data: {allSitePage: {edges}}}) => (
	<Layout>
		<SEO title="Home" />
		<Posts category="news"/>
		<MainPage>Fest podróże - biuro - home</MainPage>
	</Layout>
)

export const allPostPagesTitleQuery = graphql`
query  {
	allSitePage(filter: {component: {eq: "/home/tom/Dokumenty/projekty/biuropodrozy-gliwice/src/templates/postTemplate.js"}}) {
	  edges {
		node {
		  component
		  path
		}
	  }
	}
  }
`
export default IndexPage

